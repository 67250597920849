export enum EDotsNames {
  A = 'topLeft',
  B = 'topRight', //
  C = 'bottomRight',
  D = 'bottomLeft',
}

// A **** B
// *     *
// *     *
// *     *
// D **** C
